<template>
<main class="main" role="main">
    <div class="main-container">

        <div class="timezone-container mw-100">
            <div class="heading">
                Time zones in {{tz}}
            </div>
            <p>
                List of time zones in {{tz}} continent (from TZ database). Found 60 zones. If you are not
                familiar with this type of list (you don't know which city to use), you can start with searching
                time zone by country on first page. Example: Time zones in Russia or time zones in USA.
            </p>
             <div class="auto">
                <autocomplete @selected="setCity" placeholder="Search for Regions / Cities" ref="autocomplete" v-model="cityAuto" :source= "$store.state.api_url + '_/_search?q='" results-property="city" results-display="city">
                </autocomplete>
            </div><br>
            <div class="timezone-flex">
                <router-link :to="{path:'/'+tz+'/'+r.C}" v-for="r in regions">{{r.D}}</router-link>
                <img v-if="loading" src="@/assets/loading.gif">
            </div>
           

        </div>
    

    
    </div>
    <footers></footers>
</main>
</template>

<script>
import axios from 'axios'
import moment from "moment"
import footers from '@/views/Footer.vue'
import Autocomplete from 'vuejs-auto-complete'
export default {
    components: {
        footers,
        Autocomplete
    },
    data() {
        return {
            events: [],
            tz: {},
            file_url: this.$store.state.file_url,
            homePage: [],
            api_url: "https://howmanydaystill.com/_read",
            regions: [],
            tz: false,
            loading: false,
            cityAuto: ""
            //api_url:"http://localhost:5000/_read",
        }
    },
    watch: {
        '$route': {
            handler(newValue, oldValue) {
                this.tz = this.$route.params.region
                this.init()
            }
        }
    },
    created() {
        this.tz = this.$route.params.region
        this.init()
    },
    methods: {
        setCity() {
            setTimeout(f => {
                this.$router.push({
                    path: "/" + this.cityAuto
                });
            }, 200)

        },
        init() {
            this.loading = true;
            this.regions = []
            axios.post(this.$store.state.api_url + "data", {
                region: this.$route.params.region
            }).then(response => {
                this.regions = (response.data.data)
                this.loading = false
            }).catch(error => {

            })
        }
    }
}
</script>

<style scoped>
.auto {
    width: 97%;
    margin: 0 auto;
    margin-top: 25px;
}
</style>
